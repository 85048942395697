import {Component, OnInit, ElementRef, Renderer, OnDestroy} from '@angular/core';
import {ActivitiesService} from "./activities.service";
import {TareaService} from "@app/modulo-agenda/tarea/tarea.service";
import {Globals} from "@app/modulo-global/globals";
import {forkJoin} from "rxjs";
import {ChatTemaService} from "@app/modulo-agenda/tema/chat-tema/chat-tema.service";
import {ChatTareaService} from "@app/modulo-agenda/tarea/chat-tarea/chat-tarea.service";
import {UsuarioService} from "@app/modulo-usuario/usuario/usuario.service";

declare var $: any;

@Component({
    selector: 'sa-activities',
    templateUrl: './activities.component.html',
    providers: [ActivitiesService],
})
export class ActivitiesComponent implements OnInit, OnDestroy {
    count: number;
    lastUpdate: any;
    active: boolean;
    activities: any;
    currentActivity: any;
    loading: boolean;
    public fechaHoy: any;
    public tareasVencidas: number;
    public ProximosCumpleanos: { nombre: string, fecha_cumpleanos: string, dias_para_cumpleanos: number, edad_actual: number }[];

    public notif_task: any = {
        id: null,
        timestamp: null
    };

    public array_notification;

    constructor(
        private el: ElementRef,
        private renderer: Renderer,
        private activitiesService: ActivitiesService,
        private tareaService: TareaService,
        private chatTemaService: ChatTemaService,
        private chatTareaService: ChatTareaService,
        private global: Globals,
        private usuarioService: UsuarioService
    ) {
        this.active = false;
        this.loading = false;
        this.activities = [];
        this.count = 0;
        this.lastUpdate = new Date();
        this.fechaHoy = new Date();
        this.tareasVencidas = 0;
    }

    ngOnInit() {
        this.update();
    }

    setActivity(activity) {
        this.currentActivity = activity;
    }

    private documentSub: any;

    onToggle() {
        let dropdown = $('.ajax-dropdown', this.el.nativeElement);
        this.active = !this.active;
        if (this.active) {
            dropdown.fadeIn();

            this.documentSub = this.renderer.listenGlobal('document', 'mouseup', (event) => {
                if (!this.el.nativeElement.contains(event.target)) {
                    dropdown.fadeOut();
                    this.active = false;
                    this.documentUnsub()
                }
            });


        } else {
            dropdown.fadeOut();

            this.documentUnsub()
        }
    }


    update() {
        this.loading = true;
        this.count = 0;
        this.tareasVencidas = 0;
        this.array_notification = [];

        this.activitiesService.getActivities().subscribe(data => {
            this.activities = data;
            forkJoin(
                this.tareaService.misTareas(),
                this.tareaService.obtenerTareasCompartidas(),
            ).subscribe(([misTareas, tareasAsignadas]: [any, any]) => {
                let tasks = [];
                misTareas.forEach((value: any, key: string) => {
                    value.type = 'tarea';
                });
                for (let i = 0; i < misTareas.length; i++) {
                    tasks.push(misTareas[i]);
                    if (misTareas[i].fecha_programada) {
                        if (this.fechaHoy >= new Date(misTareas[i].fecha_programada)) {
                            this.tareasVencidas++;
                        }
                        if (this.global.calcularHorasRestantes(new Date(), misTareas[i].fecha_programada)) {
                            this.actualizarRegistroNotificacionTareas(misTareas[i].id_tarea);
                        }
                    }
                }
                tareasAsignadas.forEach((value: any, key: string) => {
                    value.type = 'tarea';
                });
                for (let i = 0; i < tareasAsignadas.length; i++) {
                    tasks.push(tareasAsignadas[i]);
                    if (tareasAsignadas[i].fecha_programada) {
                        if (this.fechaHoy >= new Date(tareasAsignadas[i].fecha_programada)) {
                            this.tareasVencidas++;
                        }
                        if (this.global.calcularHorasRestantes(new Date(), tareasAsignadas[i].fecha_programada)) {
                            this.actualizarRegistroNotificacionTareas(tareasAsignadas[i].id_tarea);
                        }
                    }
                }
                for (let i = 1; i < this.activities.length; i++) {
                    if (this.activities[i].name == 'tareas') {
                        this.activities[i].data = tasks;
                        break;
                    }
                }
                this.count += this.tareasVencidas;
                localStorage.setItem('notif_task', JSON.stringify(this.array_notification));
                localStorage.removeItem('temporizador_task_id');
            });

            this.currentActivity = data[1];

            // MENCIONES EN CHAT
            forkJoin([
                this.chatTemaService.getUnreadChats(),
                this.chatTareaService.getUnreadChats()
            ]).subscribe(([chatsTemaNoLeidos, chatsTareaNoLeidos]: [{ tema_id: number, usuario_id: number, ultimo_acceso: string, fecha: string, nro_mensajes : number, titulo: string, type: string }[], { tarea_id: number, usuario_id: number, ultimo_acceso: string, fecha: string, nro_mensajes : number, titulo: string, type: string }[]]) => {
                for (let i = 0; i < chatsTemaNoLeidos.length; i++) {
                    chatsTemaNoLeidos[i]['type'] = 'notification';
                    chatsTemaNoLeidos[i]['message'] = 'Mensaje' + (chatsTemaNoLeidos[i].nro_mensajes > 1 ? 's' : '') + ' sin leer en el chat del tema "' + chatsTemaNoLeidos[i].titulo + '"';
                    chatsTemaNoLeidos[i]['icon'] = chatsTemaNoLeidos[i].nro_mensajes == 1 ? 'fa-comment-o' : 'fa-comments-o';
                    chatsTemaNoLeidos[i]['tipo'] = 'tema';
                    chatsTemaNoLeidos[i]['time'] = this.global.calcularHorasRestantes(new Date(), chatsTemaNoLeidos[i].ultimo_acceso);
                }

                for (let i = 0; i < chatsTareaNoLeidos.length; i++) {
                    chatsTareaNoLeidos[i]['type'] = 'notification';
                    chatsTareaNoLeidos[i]['message'] = 'Mensaje' + (chatsTareaNoLeidos[i].nro_mensajes > 1 ? 's' : '') + ' sin leer la tarea "' + chatsTareaNoLeidos[i].titulo + '"';
                    chatsTareaNoLeidos[i]['icon'] = chatsTareaNoLeidos[i].nro_mensajes == 1 ? 'fa-comment-o' : 'fa-comments-o';
                    chatsTareaNoLeidos[i]['tipo'] = 'tarea';
                    chatsTareaNoLeidos[i]['time'] = this.global.calcularHorasRestantes(new Date(), chatsTareaNoLeidos[i].ultimo_acceso);
                }

                this.count += chatsTemaNoLeidos.length + chatsTareaNoLeidos.length;

                for (let i = 1; i < this.activities.length; i++) {
                    if (this.activities[i].name == 'notification') {
                        this.activities[i].data = [];
                        this.activities[i].data.push(...chatsTareaNoLeidos);
                        this.activities[i].data.push(...chatsTemaNoLeidos);
                        break;
                    }
                }
                this.getProximosCumpleanos();
            });
        });

        this.lastUpdate = new Date();
        this.loading = false;
    }


    ngOnDestroy() {
        this.documentUnsub()
    }

    documentUnsub() {
        this.documentSub && this.documentSub();
        this.documentSub = null
    }

    public actualizarRegistroNotificacionTareas(id) {
        this.notif_task.id = id;
        this.notif_task.timestamp = null;

        let json = JSON.parse(localStorage.getItem('notif_task'));
        if (!json) {
            this.array_notification.push(this.notif_task);
            return;
        }
        for (let i = 0; i < json.length; i++) {
            if (json[i].id == id) {
                this.array_notification.push(json[i]);
                return;
            }
        }
        this.array_notification.push(this.notif_task);
    }

    public getProximosCumpleanos() {
        this.usuarioService
            .getProximosCumpleanos()
            .then((pc) => {
                this.ProximosCumpleanos = pc.filter(x => x.dias_para_cumpleanos < 5);
                for (let i = 0; i < this.ProximosCumpleanos.length; i++) {
                    let item: any = [];
                    item['type'] = 'notification';
                    item['message'] = this.ProximosCumpleanos[i].dias_para_cumpleanos == 0 ? 'Hoy es el cumpleaños de ' + this.ProximosCumpleanos[i].nombre + '!!' : 'El cumpleaños de ' + this.ProximosCumpleanos[i].nombre + (this.ProximosCumpleanos[i].dias_para_cumpleanos > 1 ? ' ¡es en ' + this.ProximosCumpleanos[i].dias_para_cumpleanos + ' días!' : ' es mañana!');
                    item['icon'] = 'fa-star-o';
                    item['tipo'] = 'cumple';
                    item['time'] = null;
                    for (let i = 1; i < this.activities.length; i++) {
                        if (this.activities[i].name == 'notification') {
                            this.activities[i].data.push(item);
                            break;
                        }
                    }
                }
            })
    }
}
