import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import {EmptyLayoutComponent} from "@app/shared/layout/app-layouts/empty-layout.component";

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    data: {pageTitle: 'Login'},
    children: [
      {
        path: '', redirectTo: 'auth/login', pathMatch: 'full',
      },
      {path: 'auth', loadChildren: 'app/auth/auth.module#AuthModule', data: {pageTitle: 'Login'}},
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    data: {pageTitle: 'Principal'},
    children: [
      {
        path: '', redirectTo: 'principal', pathMatch: 'full',
      },
      {path: 'principal', loadChildren: 'app/home/home.module#HomeModule', data: {pageTitle: 'Principal'}},
      {path: 'auth', loadChildren: 'app/auth/auth.module#AuthModule', data: {pageTitle: 'Login'}},
      {path: 'usuario', loadChildren: 'app/modulo-usuario/usuario/usuario.module#UsuarioModule', data: {pageTitle: 'Usuarios'}},
      {path: 'comedor', loadChildren: 'app/modulo-usuario/comedor/comedor.module#ComedorModule', data: {pageTitle: 'Peñas'}},
      {path: 'usuario-externo', loadChildren: 'app/modulo-usuario/usuario-externo/usuario-externo.module#UsuarioExternoModule', data: {pageTitle: 'Usuarios externos'}},
    ]
  },
  {
    path: 'producto',
    component: MainLayoutComponent,
    data: {pageTitle: 'Producto'},
    children: [
      {
        path: '', redirectTo: 'principal', pathMatch: 'full',
      },
      // MODULO PRODUCTOS:
      {path: 'familia-mp-axia', loadChildren: 'app/modulo-producto/materia-prima/familia-mp-axia/familia-mp-axia.module#FamiliaMpAxiaModule', data: {pageTitle: 'Familia de materias primas de Axia'}},
      {path: 'tipo-perfil-axia', loadChildren: 'app/modulo-producto/perfil/tipo-perfil-axia/tipo-perfil-axia.module#TipoPerfilAxiaModule', data: {pageTitle: 'Tipo de perfil Axia'}},
      {path: 'fabricantes', loadChildren: 'app/fabricante/fabricante/fabricante.module#FabricanteModule', data: {pageTitle: 'Fabricantes'}},
      {path: 'materia-prima-axia', loadChildren: 'app/modulo-producto/materia-prima/materia-prima-axia/materia-prima-axia.module#MateriaPrimaAxiaModule', data: {pageTitle: 'Materia prima Axia'}},
      {path: 'clase-perfil-axia', loadChildren: 'app/modulo-producto/perfil/clase-perfil-axia/clase-perfil-axia.module#ClasePerfilAxiaModule', data: {pageTitle: 'Perfiles Axia'}},
      {path: 'tipo', loadChildren: 'app/modulo-producto/tipo/tipo/tipo.module#TipoModule', data: {pageTitle: 'Tipo de producto'}},
      {path: 'aplicacion-perfil', loadChildren: 'app/modulo-producto/aplicacion-perfil/aplicacion-perfil.module#AplicacionPerfilModule', data: {pageTitle: 'Aplicación de perfil'}},
      {path: 'aplicacion-tipoperfil', loadChildren: 'app/modulo-producto/aplicacion-tipoperfil/aplicacion-tipoperfil.module#AplicacionTipoperfilModule', data: {pageTitle: 'Aplicación para los tipos de perfiles'}},
      {path: 'aplicacion-tipo-producto', loadChildren: 'app/modulo-producto/tipo/aplicacion-tipo/aplicacion-tipo.module#AplicacionTipoModule', data: {pageTitle: 'Aplicación para los tipos de productos'}},
      {path: 'perfil', loadChildren: 'app/modulo-producto/perfil/perfil/perfil.module#PerfilModule', data: {pageTitle: 'Perfil'}},
      {path: 'marca-fabricante', loadChildren: 'app/fabricante/marca-fabricante/marca-fabricante.module#MarcaFabricanteModule', data: {pageTitle: 'Marcas de Fabricante'}},
      {path: 'materia-prima', loadChildren: 'app/modulo-producto/materia-prima/materia-prima/materia-prima.module#MateriaPrimaModule', data: {pageTitle: 'Materia prima'}},
      {path: 'origen-componente', loadChildren: 'app/modulo-producto/componente/origen-componente/origen-componente.module#OrigenComponenteModule', data: {pageTitle: 'Origen de componente'}},
      {path: 'parametros-componentes', loadChildren: 'app/modulo-producto/componente/parametros-componentes-layout/parametros-componentes-layout.module#ParametrosComponentesLayoutModule', data: {pageTitle: 'Parámetros de componentes'}},
      {path: 'origen-mecanizado', loadChildren: 'app/modulo-producto/producto/origen-mecanizado/origen-mecanizado.module#OrigenMecanizadoModule', data: {pageTitle: 'Origen de mecanizado'}},
      {path: 'clase-componente', loadChildren: 'app/modulo-producto/componente/clase-componente/clase-componente.module#ClaseComponenteModule', data: {pageTitle: 'Clase de componente'}},
      {path: 'disponibilidad-mercado', loadChildren: 'app/modulo-producto/producto/disponibilidad-mercado/disponibilidad-mercado.module#DisponibilidadMercadoModule', data: {pageTitle: 'Disponibilidad de mercado'}},
      {path: 'perfil-componente', loadChildren: 'app/modulo-producto/componente/perfil-componente/perfil-componente.module#PerfilComponenteModule', data: {pageTitle: 'Perfil Componente'}},
      {path: 'familia-producto', loadChildren: 'app/modulo-producto/perfil/familia-producto/familia-producto.module#FamiliaProductoModule', data: {pageTitle: 'Perfil, materia prima y marca'}},
      {path: 'componente-familia-producto', loadChildren: 'app/modulo-producto/componente/componente-perfil-mp-marca/componente-perfil-mp-marca.module#ComponentePerfilMpMarcaModule', data: {pageTitle: 'Componente Perfil, materia prima y marca'}},
      {path: 'producto', loadChildren: 'app/modulo-producto/producto/producto/producto.module#ProductoModule', data: {pageTitle: 'Producto'}},
      {path: 'dimension', loadChildren: 'app/modulo-producto/producto/dimension/dimension.module#DimensionModule', data: {pageTitle: 'Dimensiones'}},
      {path: 'kit', loadChildren: 'app/modulo-producto/producto/kit/kit.module#KitModule', data: {pageTitle: 'Kits de productos'}},
      {path: 'kit-productos', loadChildren: 'app/modulo-producto/producto/kit-productos/kit-productos.module#KitProductosModule', data: {pageTitle: 'Productos por Kit'}},
      {path: 'reservas', loadChildren: 'app/modulo-producto/reserva-material/reserva-material.module#ReservaMaterialModule', data: {pageTitle: 'Reserva de materiales'}},
    ]
  },
  {
    path: 'regional',
    component: MainLayoutComponent,
    data: {pageTitle: 'Regional'},
    children: [
      {
        path: '', redirectTo: 'principal', pathMatch: 'full',
      },
      // MODULO REGIONAL:
      {path: 'divisa', loadChildren: 'app/modulo-regionalizacion/divisa/divisa.module#DivisaModule', data: {pageTitle: 'Divisa'}},
      {path: 'pais', loadChildren: 'app/modulo-regionalizacion/pais/pais.module#PaisModule', data: {pageTitle: 'Países'}},
      {path: 'provincia', loadChildren: 'app/modulo-regionalizacion/provincia/provincia.module#ProvinciaModule', data: {pageTitle: 'Provincias'}},
      {path: 'region', loadChildren: 'app/modulo-regionalizacion/region/region.module#RegionModule', data: {pageTitle: 'Regiones'}},
      {path: 'localidad', loadChildren: 'app/modulo-regionalizacion/localidad/localidad.module#LocalidadModule', data: {pageTitle: 'Localidades'}},
    ]
  },
  {
    path: 'global',
    component: MainLayoutComponent,
    data: {pageTitle: 'Opciones globales'},
    children: [
      {
        path: '', redirectTo: 'principal', pathMatch: 'full',
      },
      // MODULO DE OPCIONES GLOBALES:
      {path: 'unidad-medida', loadChildren: 'app/modulo-global/unidad-medida/unidad-medida.module#UnidadMedidaModule', data: {pageTitle: 'Unidades de medida'}},
      {path: 'clase-unidad-medida', loadChildren: 'app/modulo-global/clase-unidad-medida/clase-unidad-medida.module#ClaseUnidadMedidaModule', data: {pageTitle: 'Unidades de medida'}},
      {path: 'estado-entidad', loadChildren: 'app/modulo-global/estado-entidad/estado-entidad.module#EstadoEntidadModule', data: {pageTitle: 'Estado de entidades'}},
      {path: 'forma-pago', loadChildren: 'app/modulo-global/forma-pago/forma-pago.module#FormaPagoModule', data: {pageTitle: 'Formas de pago'}},
      {path: 'panel-control', loadChildren: 'app/modulo-global/panel-control/panel-control.module#PanelControlModule', data: {pageTitle: 'Panel de control'}},
      {path: 'condicion-iva', loadChildren: 'app/modulo-global/condicion-iva/condicion-iva.module#CondicionIvaModule', data: {pageTitle: 'Condición de IVA'}},
      {path: 'tratamiento-envio', loadChildren: 'app/modulo-global/condicion-entrega/tratamiento-envio/tratamiento-envio.module#TratamientoEnvioModule', data: {pageTitle: 'Tratamiento de envíos'}},
      {path: 'condicion-entrega', loadChildren: 'app/modulo-global/condicion-entrega/condicion-entrega.module#CondicionEntregaModule', data: {pageTitle: 'Condición de entrega'}},
    ]
  },
  {
    path: 'proveedor',
    component: MainLayoutComponent,
    data: {pageTitle: 'Proveedores'},
    children: [
      {
        path: '', redirectTo: 'principal', pathMatch: 'full',
      },
      // MODULO DE PROVEEDORES:
      {path: 'clasificacion-proveedor', loadChildren: 'app/modulo-proveedor/clasificacion-proveedor/clasificacion-proveedor.module#ClasificacionProveedorModule', data: {pageTitle: 'Clasificación del proveedor'}},
      {path: 'familia-clasificacion-proveedor', loadChildren: 'app/modulo-proveedor/familia-clasificacion-proveedor/familia-clasificacion-proveedor.module#FamiliaClasificacionProveedorModule', data: {pageTitle: 'Familia de clasificaciones de proveedor'}},
      {path: 'proveedor', loadChildren: 'app/modulo-proveedor/proveedor/proveedor.module#ProveedorModule', data: {pageTitle: 'Proveedores'}},
      {path: 'estado-lista-precio', loadChildren: 'app/modulo-proveedor/estado-lista-precio/estado-lista-precio.module#EstadoListaPrecioModule', data: {pageTitle: 'Estado de listas de precios'}},
      {path: 'proveedor-contacto', loadChildren: 'app/modulo-proveedor/proveedor-contacto/proveedor-contacto.module#ProveedorContactoModule', data: {pageTitle: 'Contacto de proveedor'}},
      {path: 'tipo-precio', loadChildren: 'app/modulo-proveedor/tipo-precio/tipo-precio.module#TipoPrecioModule', data: {pageTitle: 'Tipos de precios'}},
      {path: 'lista-precio', loadChildren: 'app/modulo-proveedor/lista-precio/lista-precio.module#ListaPrecioModule', data: {pageTitle: 'Listas de precios'}},
      {path: 'proveedor-perfil-marca-mp', loadChildren: 'app/modulo-proveedor/proveedor-perfil-marca-mp/proveedor-perfil-marca-mp.module#ProveedorPerfilMarcaMpModule', data: {pageTitle: 'Relación Proveedor - Perfil - Marca - Materia Prima'}},
      {path: 'insumo-proveedor', loadChildren: 'app/modulo-proveedor/insumo-proveedor/insumo-proveedor.module#InsumoProveedorModule', data: {pageTitle: 'Insumo Proveedor'}},
      {path: 'precio-referencia', loadChildren: 'app/modulo-proveedor/precio-referencia-cotizacion/precio-referencia-cotizacion.module#PrecioReferenciaCotizacionModule', data: {pageTitle: 'Precio de referencia cotización'}},
      {path: 'precio-insumo', loadChildren: 'app/modulo-proveedor/precio-insumo-proveedor/precio-insumo-proveedor.module#PrecioInsumoProveedorModule', data: {pageTitle: 'Precio del insumo'}},
      {path: 'supplier-workplace', loadChildren: 'app/modulo-proveedor/proveedor/supplier-workplace/supplier-workplace.module#SupplierWorkplaceModule', data: {pageTitle: 'Suppliers workplace'}},
    ]
  },
  {
    path: 'inventario',
    component: MainLayoutComponent,
    data: {pcomponentesageTitle: 'Inventario'},
    children: [
      {
        path: '', redirectTo: 'principal', pathMatch: 'full',
      },
      // MODULO DE INVENTARIO:
      {path: 'clasificacion-producto-fisico', loadChildren: 'app/modulo-inventario/estado-producto-fisico/estado-producto-fisico.module#EstadoProductoFisicoModule', data: {pageTitle: 'Estado del producto físico'}},
      {path: 'recepcion-material', loadChildren: 'app/modulo-inventario/recepcion-material/recepcion-material.module#RecepcionMaterialModule', data: {pageTitle: 'Recepción de Material'}},
      {path: 'producto-fisico', loadChildren: 'app/modulo-inventario/producto-fisico/producto-fisico.module#ProductoFisicoModule', data: {pageTitle: 'Producto Físico'}},
      {path: 'salida-material', loadChildren: 'app/modulo-inventario/salida-material/salida-material.module#SalidaMaterialModule', data: {pageTitle: 'Salida de material'}},
      {path: 'direcciones-de-almacenamiento', loadChildren: 'app/modulo-inventario/almacenamiento/local-almacenamiento/local-almacenamiento.module#LocalAlmacenamientoModule', data: {pageTitle: 'Direcciones de almacenamiento'}},
      {path: 'extracciones', loadChildren: 'app/modulo-inventario/panel-extracciones-material/panel-extracciones-material.module#PanelExtraccionesMaterialModule', data: {pageTitle: 'Panel de extracciones de materiales'}},
      {path: 'stock', loadChildren: 'app/modulo-inventario/stock/stock.module#StockModule', data: {pageTitle: 'STOCK'}},
    ]
  },
  {
    path: 'cliente',
    component: MainLayoutComponent,
    data: {pageTitle: 'Clientes'},
    children: [
      {
        path: '', redirectTo: 'principal', pathMatch: 'full',
      },
      // MODULO DE CLIENTE:
      {path: 'estado-homologacion', loadChildren: 'app/modulo-cliente/estado-homologacion/estado-homologacion.module#EstadoHomologacionModule', data: {pageTitle: 'Estados de Homologación'}},
      {path: 'fuente-contacto', loadChildren: 'app/modulo-cliente/fuente-contacto/fuente-contacto.module#FuenteContactoModule', data: {pageTitle: 'Fuente de contacto externo'}},
      {path: 'situacion-vinculo', loadChildren: 'app/modulo-cliente/situacion-vinculo/situacion-vinculo.module#SituacionVinculoModule', data: {pageTitle: 'Situación del vínculo'}},
      {path: 'clasificacion-cliente', loadChildren: 'app/modulo-cliente/clasificacion-cliente/clasificacion-cliente.module#ClasificacionClienteModule', data: {pageTitle: 'Clasificación de cliente'}},
      {path: 'familia-industria', loadChildren: 'app/modulo-cliente/familia-industria/familia-industria.module#FamiliaIndustriaModule', data: {pageTitle: 'Familia de industrias'}},
      {path: 'industria', loadChildren: 'app/modulo-cliente/industria/industria.module#IndustriaModule', data: {pageTitle: 'Industrias'}},
      {path: 'cliente', loadChildren: 'app/modulo-cliente/cliente/cliente.module#ClienteModule', data: {pageTitle: 'Clientes'}},
      {path: 'planta-cliente', loadChildren: 'app/modulo-cliente/planta-cliente/planta-cliente.module#PlantaClienteModule', data: {pageTitle: 'Plantas'}},
      {path: 'sector-planta', loadChildren: 'app/modulo-cliente/sector-planta/sector-planta.module#SectorPlantaModule', data: {pageTitle: 'Sector de planta'}},
      {path: 'cliente-contacto', loadChildren: 'app/modulo-cliente/cliente-contacto/cliente-contacto.module#ClienteContactoModule', data: {pageTitle: 'Contactos'}},
      {path: 'cliente-kit', loadChildren: 'app/modulo-cliente/cliente-kit/cliente-kit.module#ClienteKitModule', data: {pageTitle: 'Kits de productos de cliente'}},
      {path: 'parametros-cliente', loadChildren: 'app/modulo-cliente/parametros-cliente-layout/parametros-cliente-layout.module#ParametrosClienteLayoutModule', data: {pageTitle: 'Parámetros de clientes'}},
      {path: 'menu-cps', loadChildren: 'app/modulo-cliente/cliente-planta-sector/cliente-planta-sector.module#ClientePlantaSectorModule', data: {pageTitle: 'Panel de cliente'}},
    ]
  },
  {
    path: 'agenda',
    component: MainLayoutComponent,
    data: {pageTitle: 'Agenda'},
    children: [
      /*{
        path: '', redirectTo: 'principal', pathMatch: 'full',
      },*/
      // MODULO DE AGENDA:
        {path: 'estado-avance', loadChildren: 'app/modulo-agenda/estado-avance/estado-avance.module#EstadoAvanceModule', data: {pageTitle: 'Estados de avance de un tema'}},
        {path: 'disparador-actividad', loadChildren: 'app/modulo-agenda/disparador-actividad/disparador-actividad.module#DisparadorActividadModule', data: {pageTitle: 'Disparadores de actividades'}},
        {path: 'tipo-actividad', loadChildren: 'app/modulo-agenda/tipo-actividad/tipo-actividad.module#TipoActividadModule', data: {pageTitle: 'Tipos de actividades'}},
        {path: 'estado-tarea', loadChildren: 'app/modulo-agenda/estado-tarea/estado-tarea.module#EstadoTareaModule', data: {pageTitle: 'Estados de tareas'}},
        {path: 'actividad', loadChildren: 'app/modulo-agenda/actividad/actividad.module#ActividadModule', data: {pageTitle: 'Actividades'}},
        {path: 'actividades', loadChildren: 'app/modulo-agenda/actividades-explorador/explorador-actividades.module#ExploradorActividadesModule', data: {pageTitle: 'Actividades'}},
        {path: 'tema', loadChildren: 'app/modulo-agenda/tema/tema.module#TemaModule', data: {pageTitle: 'Temas'}},
        {path: 'tarea', loadChildren: 'app/modulo-agenda/tarea/tarea.module#TareaModule', data: {pageTitle: 'Tareas'}},
        {path: 'tareas', loadChildren: 'app/modulo-agenda/tareas-explorador/explorador-tareas.module#ExploradorTareasModule', data: {pageTitle: 'Explorador de Tareas'}},
        {path: 'modalidad', loadChildren: 'app/modulo-agenda/modalidad/modalidad.module#ModalidadModule', data: {pageTitle: 'Modalidades'}},
        {path: 'calendar', loadChildren: 'app/modulo-agenda/calendar/calendar.module#CalendarModule', data: {pageTitle: 'Calendar'}},
        {path: 'clasificacion-tema', loadChildren: 'app/modulo-agenda/tema-clasificacion/tema-clasificacion.module#TemaClasificacionModule', data: {pageTitle: 'Clasificación de temas'}},
        {path: 'clasificacion-tarea', loadChildren: 'app/modulo-agenda/tarea-clasificacion/tarea-clasificacion.module#TareaClasificacionModule', data: {pageTitle: 'Clasificación de tareas'}},
        {path: 'temas', loadChildren: 'app/modulo-agenda/temas-explorador/explorador-temas.module#ExploradorTemasModule', data: {pageTitle: 'Temas'}},
        {path: 'notas', loadChildren: 'app/modulo-agenda/notas/notas.module#NotasModule', data: {pageTitle: 'Notas'}},
        {path: 'tarea-actividades', loadChildren: 'app/modulo-agenda/tarea/timeline-tarea-actividades/timeline-tarea-actividades.module#TimelineTareaActividadesModule', data: {pageTitle: 'Timeline de tareas y actividades'}},
    ]
  },
    {
      path: 'cotizaciones',
      component: MainLayoutComponent,
      data: {pageTitle: 'Cotizaciones'},
      children: [
        // MODULO DE COTIZACIONES:
        {path: 'dispositivos-relevamiento', loadChildren: 'app/modulo-cotizador/relevamiento/dispositivo-relevamiento/dispositivo-relevamiento.module#DispositivoRelevamientoModule', data: {pageTitle: 'Dispositivos de relevamiento'}},
        {path: 'rc', loadChildren: 'app/modulo-cotizador/requerimiento-cliente/requerimiento-cliente.module#RequerimientoClienteModule', data: {pageTitle: 'Requisiciones de cliente'}},
        {path: 'generar-pedido', loadChildren: 'app/modulo-cotizador/generador-pedido/pedido.module#PedidoModule', data: {pageTitle: 'Ordenes de trabajo'}},
        {path: 'legajo-produccion', loadChildren: 'app/modulo-cotizador/visualizador-legajo/visualizador-legajo-produccion.module#VisualizadorLegajoProduccionModule', data: {pageTitle: 'Legajo de producción'}},
      ]
    },
    {
      path: 'administracion',
      component: MainLayoutComponent,
      data: {pageTitle: 'Administración'},
      children: [
        // MODULO DE ADMINISTRACIÓN:
        {path: 'reportes-tango', loadChildren: 'app/modulo-administracion/reportes-tango/reportes.module#ReportesTangoModule', data: {pageTitle: 'Reportes de Tango Gestión'}},
        {path: 'portales', loadChildren: 'app/modulo-administracion/portales/portales.module#PortalesModule', data: {pageTitle: 'Gestión de Portales'}},
      ]
    },
    {
        path: '',
        component: EmptyLayoutComponent,
        data: {pageTitle: 'Laboratorio'},
        children: [
            {path: 'labs', loadChildren: 'app/features/miscellaneous/blank/blank.module#BlankModule'},
            {path: 'quirm', loadChildren: 'app/modulo-inventario/quick-interface-retiro-material/quirm.module#QuirmModule', data: {pageTitle: 'QuickInterface Retiro de material'}},
            {path: 'lala', loadChildren: 'app/modulo-producto/componente/componente-perfil-mp-marca/componente-perfil-mp-marca.module#ComponentePerfilMpMarcaModule', data: {pageTitle: 'Requisiciones de cliente'}},
        ]
    },
  // { path: 'auth', component: AuthLayoutComponent, loadChildren: 'app/+auth/auth.module#AuthModule'},

  //{path: '**', redirectTo: 'principal'}
  //{ path: "**", redirectTo: "miscellaneous/error404" }
   {
        path: '',
        component: MainLayoutComponent,
        data: {pageTitle: 'Página no encontrada'},
        children: [
            {
                path: '**', loadChildren: 'app/modulo-usuario/error404/error404.module#Error404Module'
            }
        ]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: "reload" })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
